<template>
  <appointment-history title="Previous consultations" />
</template>

<script>
import AppointmentHistory from '@/components/appointments/AppointmentHistory';

export default {
  components: {
    AppointmentHistory,
  },
};
</script>

<style lang="postcss"></style>
